<script setup lang="ts">
import useEmblaCarousel from 'embla-carousel-vue'
import type { EmblaOptionsType, EmblaCarouselType } from 'embla-carousel'

const props = withDefaults(
  defineProps<{
    options?: EmblaOptionsType
    enabled?: boolean
  }>(),
  {
    options: () => ({}),
    enabled: true,
  }
)

const emit = defineEmits<{
  'embla-init': [embla: EmblaCarouselType]
}>()

const [emblaNode, emblaApi] = useEmblaCarousel(props.options)

// Watch for changes in enabled prop
watch(
  () => props.enabled,
  newVal => {
    if (emblaApi.value) {
      emblaApi.value.reInit({
        ...props.options,
        active: newVal,
      })
    }
  }
)

// Watch for changes in options
watch(
  () => props.options,
  newVal => {
    if (emblaApi.value) {
      emblaApi.value.reInit(newVal)
    }
  },
  { deep: true }
)

onMounted(() => {
  if (emblaApi.value) {
    emit('embla-init', emblaApi.value)
  }
})

onBeforeUnmount(() => {
  if (emblaApi.value) {
    emblaApi.value.destroy()
  }
})

// Expose embla API to parent components
defineExpose({
  emblaApi,
})
</script>

<template>
  <div ref="emblaNode" class="embla">
    <div
      class="embla-container"
      :class="{ 'is-vertical': options.axis === 'y' }"
    >
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.embla {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.embla-container {
  display: flex;
  height: 100%;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
}

.embla-container.is-vertical {
  flex-direction: column;
}
</style>
